import React, {useContext, useState} from 'react';
import {Sidebar} from "./Sidebar";
import Form, {IData} from "./Form";
import SuccessInfo from "./SuccessInfo";
import styled from "styled-components";
import {StartConfigContext} from "../store/context/start-config";
import submit from "../helpers/submit";
interface IWidgetProps {
  sidebar: boolean;
  token: string;
  partner: string;
}

function Widget(props: IWidgetProps) {
  const [payedStatus, setPayedStatus] = React.useState<'success' | 'failed'>();
  const [email, setEmail] = React.useState<null | string>(null);
  const [resultHash, setResultHash] = useState<string | null>(null)
  const startConfigContext = useContext(StartConfigContext);

  async function onSubmit (formData: IData, token: string) {
    await submit(formData, setPayedStatus, setResultHash, token, props.partner);
    setEmail(formData.email);
  }

  if (!startConfigContext) return null

  return (
    <Container>
      {props.sidebar && <SidebarWrapper>
          <Sidebar/>
      </SidebarWrapper>
      }
      {payedStatus !== 'success'
        ? <Content>
          <Form
            onSubmit={onSubmit}
            price={startConfigContext?.default ?? null}
            token={props.token}
            paymentMethod={startConfigContext.paymentMethod}
            setPaymentMethod={startConfigContext.setPaymentMethod}
          />
        </Content>
        : <Content>
          <SuccessInfo email={email!} resultHash={resultHash!} />
        </Content>
      }
    </Container>
  );
}

export default Widget;

const Container = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
    overflow: auto;
    flex-direction: column;
  }`

const SidebarWrapper = styled.div`
  width: 30vw;
  height: 100vh;

  display: flex;
  justify-self: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 100vw !important;
    height: 70vh;
  }
`;

const Content = styled.div`
  height: 100vh;
  overflow: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    width: 100vw !important;
    height: fit-content !important;
    background-color: var(--bg-mobile);
    align-items: center;
    padding-top: 4rem;
    overflow: visible;
  }
`;
