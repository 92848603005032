import React from 'react';
import styled from 'styled-components';
import {CirclesIcon, QuatrefoilIcon, StarIcon} from "../assets/icons/sidebar";
import background from '../assets/sidebare-bg.png'

export function Sidebar() {
    return (
        <Container>
            <Title>
                Солнце-зажигающие действия
            </Title>
            <TitleOfSentence>
                Получите список инструкций и рекомендаций по улучшению жизни. Персонально для вас, навсегда
            </TitleOfSentence>
            <GroupOfSentence>
                <Sentence>
                    <QuatrefoilIcon/>
                    <span>Улучшите здоровье. Снимите напряжение, усталость, тревогу или апатию</span>
                </Sentence>
                <Sentence>
                    <CirclesIcon/>
                    <span>Найдите силы для саморазвития и обучения, общения с близкими и собой</span>
                </Sentence>
                <Sentence>
                    <StarIcon/>
                    <span>Ставьте перед собой цели и успешно достигайте их. Преодолейте сомнения и страхи</span>
                </Sentence>
            </GroupOfSentence>
        </Container>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  height: 100vh;
  background-color: var(--bg-mobile);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-position: 50% -200px;
  padding: 0 3rem;

  @media (max-width: 768px) {
    justify-content: flex-start;
    height: 80vh;
    background-position: 50% -350px;
  }
`;

const Title = styled.div`
  color: var(--text-color);
  font-size: 4rem;
  font-weight: 500;
  text-align: center;
  line-height: 99.5%;
  margin: 4rem 0 2rem 0;

  @media (max-width: 1536px) {
    font-size: 2.5rem;
  }
`;

const TitleOfSentence = styled.div`
  text-align: center;
  color: var(--text-secondary);
  font-size: 1.25rem;
  line-height: 1.3;
  margin-bottom: 3rem;

  @media (max-width: 1536px) {
    font-size: 1rem;
  }
`;


const GroupOfSentence = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Sentence = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--text-color);
  background: #FFFFFF;
  border-radius: 10px;
  padding: 1.5rem 1.5rem;
  margin-bottom: 0.5rem;
  
  svg {
    width: 30%;
    margin-right: 1rem;
  }
  
  span {
    text-align: left;
  }
  
  @media (max-width: 1280px) {
    font-size: 0.8rem;
    padding: 1rem 1rem;
  }
`