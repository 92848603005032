import { Input } from '../libs';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {ChevronDownIcon} from "../assets/icons/system";

export default function Promo (
  { 
    promo, 
    setPromo
  } : {
    promo: string;
    setPromo: (p: string) => void;
  }) { 
    const [showInput, setShowInput] = useState(false);

  return (
    <Container showInput={showInput}>
      <Before opened={showInput} onClick={() => setShowInput(!showInput)}><p>Есть промокод?</p> <ChevronDownIcon /></Before>
      <InputWrapper showInput={showInput}>
        <StyledInput
          size='small'
          value={promo}
          onChange={setPromo}
          placeholder='Ваш промокод'
        />
      </InputWrapper>
    </Container>
  )
};


const Container = styled.div<{showInput: boolean}>`
  display: flex;
  flex-direction: column;
  min-height: 3rem;
  overflow: hidden;
  align-items: center;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 0.5rem;
  background-color: var(--bg-promo);
    ${props => props.showInput && css`
      padding-bottom: 0.8rem;
    `}

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

const Before = styled.div<{ opened: boolean }>`
  width: 100%;
  display: flex;
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bg-promo);

  & svg {
    transition: transform 0.2s;
    transform: rotate(${props => props.opened ? '180' : '0'}deg);
  }
`

const InputWrapper = styled.div<{showInput: boolean}>`
  width: 100%;
  ${props => !props.showInput && css`
    display: none;
  `}
`

const StyledInput = styled(Input)`
  text-align: start;

  &:focus {
    border: 1px solid var(--main-color);
  }
  
  @media (max-width: 768px){
    font-size: 1rem;
  }
`

