import { ChronosApi, IPlace, S3Urls } from "../libs";

export const mainApi = new ChronosApi(process.env.REACT_APP_BACKEND as string, '');

export const s3urls = new S3Urls();
export interface IStartConfigPrices {
  default?: number;
}

export const apiUrl = `${process.env.REACT_APP_BACKEND}/api`;

export const getStartConfigPrices = (token: string, partner: string, country: string): Promise<IStartConfigPrices> => {
  let url = `${apiUrl}/widget/${token}/config?country=${country}`
  if (partner) url += `?partner=${partner}`
  return fetch(url, {
    method: 'GET',
    credentials: 'include'
  }).then(data => data.json())
}

export const getPlaces = (name: string): Promise<IPlace[]> => {
  return fetch(`${apiUrl}/places?input=${name}`, {
    method: 'GET',
    credentials: 'include'
  })
  .then(data => data.json())
  .then((data: {data: IPlace[]}) => data.data)
}

export const getPlace = (id: number): Promise<IPlace> => {
  return fetch(`${apiUrl}/places/${id}`, {
    method: 'GET',
    credentials: 'include'
  })
  .then(data => data.json())
  .then((data: {data: IPlace}) => data.data)
}

export async function hash(message: string) {
  const uint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export async function getSiaData (hash: string) {
  const result = await fetch(`${apiUrl}/misc/sia/${hash}`, {
    method: 'GET',
    credentials: 'include'
  }).then(data => data.json())
    .then((data => data.data))

  return result
}
