import { CurrencyCode, Region } from "../libs"
import { apiUrl } from "./api";

export const getIokaLink = (type: 'back' | 'success' | 'fail'): string => {
  let link = window.location.href.replaceAll('https', 'http');
  link = link.replaceAll('http://localhost:8000', process.env.REACT_APP_SUN!);
  const url: URL = new URL(link);
  url.searchParams.append('ioka-back', type);
  return url.toString();
}

export interface PaymentMethod {
	id: Region
  country: Region
  title: string
	code: CurrencyCode
	symbol: string
}

export const paymentMethods: PaymentMethod[] = [
  {
	  id: Region.ru,
	  country: Region.ru,
	  title: 'Карта РФ',
		code: CurrencyCode.RUB,
		symbol: '₽',
	},
	{
		id: Region.kz,
		country: Region.kz,
	  title: 'Казахстанская карта',
		code: CurrencyCode.KZT,
		symbol: '₸',
	},
	{
	  id: Region.us,
	  country: Region.us,
	  title: 'Иностранная карта',
		code: CurrencyCode.USD,
		symbol: '$'
	},
]

export interface IPaymentData {
  type?: string;
  cloudPayments?: any;
  place: string;
  lat: number;
  lon: number;
  name: string;
  email: string;
  date: string;
  time: string;
  promo?: any;
  subtype?: string;
  createdAt?: number;
  partner?: string;
  operation?: any;
  token?: string;
  url?: string;
  country?: string;
  K8S_NAMESPACE?: string;
  backUrl?: string;
  failUrl?: string;
  successUrl?: string;
}

export async function getPaymentResult (dataPost: IPaymentData) {
  const result = await fetch(`${apiUrl}/widget/${dataPost.token}/payment/config`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(dataPost),
    credentials: 'include'
  }).then((response) => response.json());

  return result
}
