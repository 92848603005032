import React from 'react';
import styled from 'styled-components';

export default function SuccessInfo({ email, resultHash}: { email: string, resultHash: string }) {


  return (
    <Container>
      <Success>
        <h1>Поздравляем с покупкой!</h1>
        <Text>
          На почту <strong>{email}</strong> была отправлена ссылка на просмотр плана развития.<br/><br/>
          <a href={`${process.env.REACT_APP_SUN}/result#${resultHash}`}>Открыть в браузере</a>
        </Text>
        {/* <Questions>
          <h1>А если у меня будут вопросы?</h1>
          <Text>В письме будет ссылка на форму записи к специалисту.</Text>
          <Text>
            Он вам поможет:
            <ul>
              <li>Истолковать ИПР с учетом вашей жизненной ситуации</li>
              <li>
                Найти эффективные компенсаторные меры для проработки “минус
                состояний”
              </li>
            </ul>
          </Text>
        </Questions> */}
      </Success>
    </Container>
  );
}

const Container = styled.div`
  color: var(--text-color);
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2.8125rem 0;
`;

const Success = styled.div`
  padding: 0 1rem;
  ul {
    margin-top: 0.125rem;
    padding: 0 0 0 1rem;
    list-style: none;
  }
`;
const Questions = styled.div`
  margin-top: 3.125rem;
  margin-bottom: 4.375rem;
`;

const Text = styled.div`
  margin: 0 auto 0.625rem auto;
  line-height: 150%;

  & a {
    color: var(--main-color);
  }

  /* @media (max-width: 768px){
    margin-left: 1.5rem;
    margin-right: 2rem;
  } */
`;
