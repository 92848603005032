import React, {useState} from 'react';
import styled from 'styled-components';
import Result from './components/Result';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Widget from "./components/Widget";

import mainCss from './css/main.css?raw';

import {YMInitializer} from "react-yandex-metrika";
import { widgetUICss } from './libs';

export type WIDGET_TYPE = 'default' | 'lablife' | 'partial';

export const ymID = 49958389;

function App({
  token,
  type,
  sidebar = true,
  partner = '',
  asWebComponent
}: {
  token: string;
  type?: string | null;
  sidebar?: boolean;
  partner: string;
  asWebComponent?: boolean;
}) {
  const searchParams = new URLSearchParams(window.location.search);
  const partnerId = partner || searchParams.get('partner') || '';

  let partnerParams: any = {};
  // если пользователь не авторизован (визит)
  partnerParams = {
    from_partner: partnerId || false
  }

  return (
    <>
      {/*<YMInitializer*/}
      {/*  accounts={[ymID]}*/}
      {/*  options={{*/}
      {/*    clickmap: true,*/}
      {/*    trackLinks: true,*/}
      {/*    accurateTrackBounce: true,*/}
      {/*    webvisor: true,*/}
      {/*    userParams: {*/}
      {/*      beta: false,*/}
      {/*      ...partnerParams*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}

      <Container>
        { asWebComponent
          ? <Widget sidebar={sidebar} token={token} partner={partnerId}/>
          : <Router>
            <Routes>
              <Route path={`/`} element={<Widget sidebar={sidebar} token={token} partner={partnerId}/>}/>
              <Route path={`result`} element={<Result/>}/>
            </Routes>
          </Router>
        }
      </Container>
    </>
  );
}

export default App;

const Container = styled.div`
  ${mainCss.replace(/body\s+{/g, '').replace(/}/g, '')}
  ${widgetUICss.replace(/html\s+{/g, '').replace(/}/g, '')}

  @media (min-width: 769px) {
    overflow-x: hidden;
  }
`
