import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StartConfigProvider } from './store/context/start-config';
import './css/common.css';

const searchParams = new URLSearchParams(window.location.search);

const token = process.env.REACT_APP_TOKEN || '';
const partner = searchParams.get('partner') || '';

ReactDOM.render(
  <React.StrictMode>
    <StartConfigProvider token={token} partner={partner}>
      <App token={token} partner={partner}/>
    </StartConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
