import React from 'react';
import { getStartConfigPrices, IStartConfigPrices } from '../../helpers/api';
import { PaymentMethod, paymentMethods } from '../../helpers/payments';

export const StartConfigContext = React.createContext<StartConfigContextValue | null>(null);

interface StartConfigContextValue extends IStartConfigPrices {
  paymentMethod: PaymentMethod
  setPaymentMethod: (paymentMethod: PaymentMethod) => void
}

export function StartConfigProvider({
  children,
  token,
  partner = '',
}: {
  children: React.ReactNode
  token: string
  partner: string
}) {
  const [paymentMethod, setPaymentMethod] = React.useState(paymentMethods[0])
  const [prices, setPrices] = React.useState<IStartConfigPrices | null>(null);

  React.useEffect(() => {
    getStartConfigPrices(token, partner, paymentMethod.country).then(prices => {
      const computedPrices: IStartConfigPrices = {
        default: prices.default,
      };
      setPrices(computedPrices);
    })
  }, [paymentMethod, token, partner]);

  const startConfigContextValue: StartConfigContextValue = {
    ...prices,
    paymentMethod,
    setPaymentMethod,
  }

  return <StartConfigContext.Provider value={startConfigContextValue}>
    {children}
  </StartConfigContext.Provider>
}
