import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {StarIcon, WarningIcon} from "../assets/icons/system";
import {getSiaData} from "../helpers/api";

interface IState {
  actions: string[]
  date: string
  lat: number
  lon: number
  name: string
  place: string
  sunInHouse: string[]
  time: string
  warning: string
}

const reasons = ['вы ощущаете нехватку сил и энергии на выполнение любых дел, даже повседневных',
  'с самого утра вас одолевает лень, апатия и чувство опустошения',
  'ваша активность и мотивация день ото дня все меньше и меньше',
  'вы чувствуете неуверенность, сомнения, боитесь открыто выразить свое мнение или как-то проявить себя',
  'вы все чаще пренебрегаете своими желаниями и индивидуальностью, слепо следуете за толпой',
  'вас ничего не радует, не вдохновляет, вы живете с ощущением опустошенности и потерянности',
  'отсутствует желание создавать что-то новое, творить',
  'ваши планы и мечты так и остаются нереализованными',
  'вы живете на автопилоте - в режиме сурка или белки в колесе',
  'у вас сложности с деньгами, с отношениями, слабое здоровье и иммунитет',
  'в вашей жизни отсутствует радость и смысл',]

export function Result() {
  const [state, setState] = useState<IState | null>(null)
  const [error, setError] = useState<boolean>(false)
  const hash = window.location.hash.slice(1)

  useEffect(() => {
    getSiaData(hash)
      .then(setState)
      .catch(error => {
        setError(true)
        console.log('ERROR', error);
      });
  }, [])
  if(error) return <ErrorMessage><h2>Произошла ошибка, обратитесь в службу поддержки: <a href="mailto:support@chronos.mg">support@chronos.mg</a></h2></ErrorMessage>
  const makeList = (arr: string[] | undefined) => {
    if (arr) {
      return <List>{arr.map((item, index) => <li key={index}><StarIcon/>{item}</li>)}</List>
    }
    return <></>
  }

  function makeWarning(text: string | undefined) {
    if (text) {
      return <Block>
        <WarningIcon/>
        <p>{text}</p>
      </Block>
    }
    return <></>
  }

  return (
    <Container>
      <h1>Солнцезажигающие действия</h1>
      {state && <div className={'coordinates'}>{state.time} {state.date} {state.place}</div>}

      <div>Не секрет, что Солнце - наше главное, жизненно важное светило, наш основной источник силы, энергии и
        двигатель по жизни. Благодаря Солнцу мы просыпаемся по утрам, наполняемся ресурсом на целый день и осуществляем
        задуманное. Когда мы наполнены, мы чувствуем бодрость, радость, прилив сил и воодушевление. А это, в свою
        очередь, благоприятно сказывается на нашей самооценке, отношениях, профессиональной реализации и даже физическом
        здоровье.
      </div>

      <h2>Как понять, что ваше Солнце не работает и больше не напитывает вас:</h2>
      {makeList(reasons)}

      <div>Если хотя бы несколько пунктов про вас, значит пришло время помочь себе и вновь зажечь свое Солнце. 
        В этом вам поможет перечень простых и понятных действий, выполнение которых разбудит спящую внутри вас 
        энергию и добавит позитивных вибраций в жизнь.
      </div>
      <br/>

      <div>Выполнение солнцезажигающих действий на регулярной основе подарит вам силы, подъем энергии, ощущение полноты
        и радости жизни. Вы станете активнее, инициативнее, сможете решать сложные задачи, строить планы, реализовывать
        идеи. Обретете уверенность, гордость за себя и начнете жить интересной насыщенной жизнью.
      </div>
      <br/>

      <div>Источником энергии и силы для вас является следующие солнцезажигающие действия и привычки:</div>
      {makeList(state?.actions)}

      <div>Выбирайте рекомендации из списка и начинайте внедрять их в свою жизнь в качестве привычки или действий.
        Некоторые из них могут быть интересны и знакомы изначально, другие – вызывать сомнения и внутренний протест. Это
        тоже нормально. Со временем, выполняя пункты из списка, вы обнаружите, что вашей энергии становится больше, что
        вы стали более инициативны, что теперь смело можете заявить «Я хочу, я могу, я намерен(а)».
      </div>

      {makeWarning(state?.warning)}

      <div>В каких ситуациях и при каких обстоятельствах вы чувствуете себя уверенно и наполняетесь энергией:</div>
      {makeList(state?.sunInHouse)}

    </Container>
  );
}

export default Result;

const Container = styled.div`
  color: var(--text-secondary);
  font-size: 1.125rem;
  width: 50vw;
  margin: 0 auto;
  padding: 3rem;
  text-align: left;

  h1 {
    color: var(--text-primary);
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 0;
  }

  .coordinates {
    color: var(--gray-color);
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    width: 80vw;
    padding: 1rem 0;
  }
`
const Block = styled.div`
  display: flex;
  flex-direction: column;

  background-color: rgba(229, 83, 83, 0.05);
  border: 1px solid rgba(229, 83, 83, 1);
  padding: 1rem 1.5rem;
  border-radius: 0.375rem;
  position: relative;
  box-sizing: border-box;
  margin: 2rem 0;

  svg {
    width: 1.75rem;
    height: 1.75rem;
    margin-bottom: 1rem;
  }
`

const List = styled.ul`
  text-align: left;

  li {
    list-style-type: none;
    margin-bottom: 1rem;
    position: relative;
    padding-left: 0.5rem;
  }

  svg {
    position: absolute;
    top: 0px;
    left: -30px;
  }
`

const ErrorMessage = styled.div`
      width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`